import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
const Loading = () => {
  return (
    <AnimatePresence>
       <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: 1  }}
          exit={{ opacity: 0}}
          transition={{ duration: 1 }}
          key="splash" 
          className='w-[100vw] bg-[#2D2828] h-[100vh] flex items-center justify-center cursor-progress absolute top-0 z-[100]'>
            <img className='' src='./icon-splash.svg'/>
        </motion.div>  
    </AnimatePresence>
  )
}

export default Loading
